<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-card-title class="headline">License for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Upgrade, or downgrade your license or purchase additional user licenses.
        </p>

        <p v-if="event.license === 'STANDARD' || event.license === 'PRO'">
          <v-btn outlined color="green" @click="selectPlan(event.license)">Purchase more user credits</v-btn>
        </p>
      </v-card-text>

      <v-alert v-if="event.org" type="info" tile prominent>
        <p>
          This event is part of the <strong>{{event.org.name}}</strong> organization. License is automatically managed through the organizational subscription.
        </p>
        <p>
          <v-btn :to="{name: 'orgmanagerSubscription', params: {id: event.org.id}}">Manage Subscription</v-btn>
        </p>
      
      </v-alert>
      <v-alert v-if="status == 'refresh' && paymentStatus && paymentStatus.status == 'OK'" type="success" tile>
        <p>Thanks! Your payment was successfully processed. Please note that it can take a few minutes until your credits are updated.</p>
        <v-btn :to="{name: 'eventmanagerView', params: {id: event.id}}">Back to Event</v-btn>
      </v-alert>
      <v-alert v-else-if="status == 'cancelled' || (paymentStatus && paymentStatus.status == 'ERROR')" type="error" tile>
        Payment was cancelled or unsuccessful.
      </v-alert>
      <v-alert v-else-if="status == 'refresh'" type="info" tile>
        <v-progress-circular indeterminate :size="25" :width="5" color="white" class="mr-4"></v-progress-circular>
        Loading payment status... One minute...
      </v-alert>

      <div v-if="!paymentStatus && !status">
        <v-card-title class="subtitle">
          <span class="mr-2">Current License Plan</span>
          <span class="mr-2 font-weight-bold">{{ event.license | uppercase }}</span>
        </v-card-title>
        <v-card-text v-if="licenseInfo">
          <v-alert v-if="!licenseInfo.valid" type="error" prominent>
            <p>Invalid license. No new users can join this challenge until new credits are purchased.</p>
            <p v-if="event.license !== 'FREE'">
              <v-btn @click="selectPlan(event.license)">Purchase User Credits</v-btn>                            
            </p>
          </v-alert>
          <div class="d-flex flex-row">
            <v-progress-linear striped class="ma--4" height="25" color="green" :value="Math.round(licenseInfo.current_capacity / licenseInfo.max_capacity * 100)" />
            <div class="shrink mt-0 ml-4" style="white-space:nowrap;">{{ licenseInfo.max_capacity }} users</div>
          </div>
          <span class="text-muted">{{ licenseInfo.current_capacity }} users out of {{ licenseInfo.max_capacity }} users have joined. Actual number of joined users might have a slight processing delay.</span>
        </v-card-text>
      </div>

      <v-row class="mx-2 pricing-details">
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="white lighten-2" class="pa-4">
            <h3 class="text-center">Free</h3>
            <p>Try out for yourself and up to 5 team members.</p>
            <v-btn v-if="event.license === 'FREE'" outlined text color=""  :to="{name: 'eventmanagerView', params: {id: event.id}}">Continue on Free Plan</v-btn>
            <v-btn v-else outlined color="green" @click="selectPlan('FREE')">Select Free</v-btn>
            <hr/>
            <span class="large">FREE</span><span class="small">up to 5 users</span>
            <hr/>
            <ul class="check-list">
              <li>Up to 5 users</li>
              <li>All features from Basic plan</li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="white lighten-1" class="pa-4">
            <h3 class="text-center">Basic</h3>
            <p>All the basics you need to start your first corporate challenge.</p>

            <v-btn v-if="event.license === 'BASIC'" outlined color="green" @click="selectPlan('BASIC')">Add Credits</v-btn>
            <v-btn v-else outlined color="green" @click="selectPlan('BASIC')">Select Basic</v-btn>
            <hr/>
            <span class="large">$ 1</span><span class="small">per user <br/> per event</span>
            <hr/>
            <ul class="check-list">
              <li>Up to 250 users</li>
              <li>Auto-sync with any of our 9+ supported fitness device</li>
              <li>iOS and Android app</li>
              <li>Basic challenge types (including step and distance challenges)</li>
              <li>Social and real-time Leaderboards (with liking, commenting, and selfies)</li>
              <li>Virtual course maps</li>
              <li>Customize the branding of each challenge</li>
              <li>Self-service dashboard to view and manage all challenges</li>
            </ul>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="5" outlined color="white lighten-0" class="pa-4 popular">
            <h3 class="text-center">Pro <v-chip small outlined color="black">Most Popular</v-chip></h3>
            <p>Advanced features to host engaging challenges.</p>
            <v-btn v-if="event.license === 'PRO'" outlined color="green" @click="selectPlan('PRO')">Add Credits</v-btn>
            <v-btn v-else color="green" @click="selectPlan('PRO')">Select Pro</v-btn>
            <hr/>
            <span class="large">$ 1.5</span><span class="small">per user<br/>per event</span>
            <hr/>
            <ul class="check-list">
              <li class="font-weight-bold">All features from Basic plan</li>
              <li>Unlimited users</li>
              <li><router-link to="/slack">Slack Integration</router-link></li>
              <li>Team leaderboards</li>
              <li>Advanced challenge types (training plans, streak challenges, etc)</li>
              <li>Import Excel files with participant details</li>
              <li>Export Excel Reports</li>
              <li>Permissions and integrations</li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="grey darken-3 white--text" class="pa-4">
            <h3 class="text-center">Enterprise</h3>
            <p>Enterprise plan with advanced customization and integrations options.</p>
            <v-btn v-if="event.license === 'ENTERPRISE'" disabled text color="grey">Current</v-btn>
            <v-btn v-else text color="green" to="/contact">Contact Sales</v-btn>
            <hr/>
            <span class="large">Contact Us</span>
            <hr/>
            <ul class="check-list">
              <li class="font-weight-bold">All features from Pro plan</li>
              <li>Unlimited users</li>
              <li>SSO (Single Sign-On integrations)</li>
              <li>Custom domain</li>
              <li>API integrations</li>
              <li>Full control over design and email templates</li>
            </ul>
          </v-sheet>
        </v-col>
      </v-row>

      <v-card-text>
        <h3>Add-Ons</h3>
        <v-sheet elevation="3" color="grey lighten-4" class="pa-4 pricing-details">
          <h3>Full-Service Package</h3>
          <p>Add the Full-Service Package to your event to have us do the setup and support for your event. The Full-Service Package is available for PRO plans only.</p>
          <hr/>
          <span class="large">$ 250</span><span class="small">one-time<br/>per event</span>
        </v-sheet>
      </v-card-text>

    </v-card>   
    <v-dialog v-model="showQuantityDialog" max-width="400">
      <v-card>
        <v-card-title>{{ selectedLicense }} Plan</v-card-title>
        <v-card-text>
          <p>How many users credits do you want to purchase? The new credits will added on top of your current credits. You can always add more later on. Minimum purchase quantity is 10.</p>
          <p>Please note that you will see the name <strong>sodisp</strong> on the payment page and payment receipt. This is because {{ tenant.name }} is powered by the Sodisp Challenge Platform.</p>
          <v-text-field v-if="false" v-model="selectedLicense" disabled label="License Type"/>
          <v-text-field v-if="false" v-model="pricePerUser" disabled label="Price per user (USD)"/>
          <v-text-field v-model="quantity" prepend-icon="fa-users" type="number" min="1" :label="`Number of ${selectedLicense} user credits`" :hint="`At USD ${pricePerUser} per user credit.`" persistent-hint/>
      
          <v-input v-if="selectedLicense === 'PRO'" prepend-icon="fa-hands-helping" class="mt-4 input-row" label="Support Package" hide-details>
            <template>
              <v-btn-toggle v-model="fullService" mandatory class="multiline-btns">
                <v-btn :value="false" small>
                  Self-Service<br/>Free
                </v-btn>
                <v-btn :value="true" small>
                  Full-Service<br/>$ 250
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-input>

          <v-text-field v-model="total" prepend-icon="fa-tags" class="my-2" disabled prefix="USD" label="Checkout total" hide-details/>
          <v-btn large block color="primary" class="mt-4" @click="purchasePlan" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">Start Purchase</v-btn>
          <p class="text-muted mt-2">Secure payment using Stripe.</p>
        </v-card-text>

      </v-card>
    </v-dialog>


    <div class="secondary white--text py-6 py-md-12 mt-4">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Questions? We're here to help.</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

     <div id="page-customers" class="mt-0 blob-bg white--text mt-8">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>
          <v-col class="text-center" cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0">
              Over <strong>1,087 events</strong> have been created already. Join today!
            </p>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>

          </v-col>
        </v-row>
      </div>
    </div>
    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="4" md="2">
          <img src="https://sodisp.imgix.net/content/quote-drift-kelly.jpg?w=200&h=200&fit=facearea&faceindex=1&facepad=4&mask=ellipse&mask-bg=f5f5f5" style="max-width:100%;" />
          <p>
            Kelly Reardon<br/>
            <i>Drift Experience Manager @ <a href="https://drift.com" target="_blank">Drift</a></i>
          </p>
        </v-col>
        <v-col cols="8" md="10">
          <blockquote>
            "At Drift we had a goal of 10% participation in our first challenge and had over 20% of our employees signed up. [...] This is by far the smoothest platform I have used to date and our Drifters are already asking when the next challenge will take place."
          </blockquote>
        </v-col>
      </v-row>
    </v-container>
    </div>


  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import checkoutService from "@/services/checkoutService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import Header from './_Header.vue'
import store from "@/store";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "License",
  components: {
    Header,
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      siteData: siteData,
      selectedLicense: null,
      licenseInfo: null,
      quantity: 10,
      fullService: false,
      showQuantityDialog: false,
      status: null,
      paymentStatus: null,
      prices: {
        'FREE': 0,
        'BASIC': 1,
        'PRO': 1.5,
      }
    };
  },
  async mounted() {
    this.status = this.$route.query.status;
    await this.loadState();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadState();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.selectedLicense = this.event.license;
      await this.getLicenseInfo();
    },

    async loadState() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
        if (this.status === 'refresh') {
          await this.getStatus();
        }
      }
    },

    async getStatus() {
      this.paymentStatus = (await checkoutService.getEventStatus(this.event.id)).data;
      
      if (this.paymentStatus.status === 'INFO') {
        // schedule a next refresh in a few seconds
        setTimeout(this.getStatus, 5 * 1000);
      }
      else if (this.paymentStatus.status === 'OK') {
        this.$analytics.event('purchase', {
          currency: 'USD',
          value: this.paymentStatus.value,
          transaction_id: this.paymentStatus.id,
        });

        await this.getLicenseInfo();
        // also schedule a refresh in a few seconds to give the webhook call some time to arrive
        setTimeout(this.getLicenseInfo, 2 * 1000);
      }
    },

    async getLicenseInfo() {
      this.licenseInfo = (await eventManagerService.getLicenseStatus(this.event.id)).data;
    },

    async selectPlan(license) {
      this.selectedLicense = license;
      this.showQuantityDialog = true;
    },
    async useFree() {
      //this.selectedLicense = license;
      //this.showQuantityDialog = true;
    },
    async purchasePlan() {
      const request = {
        license: this.selectedLicense,
        initial_quantity: this.quantity,
        full_service: this.fullService,
        event_id: this.event.id,
      };
      this.$analytics.event('begin_checkout', {
        currency: 'USD',
        value: this.total,
      });

      this.isLoading = true;
      const response = (await checkoutService.createEventPaymentSession(request)).data;
      this.valid = true;
      this.isLoading = false;
      window.location.href = response.url

      /*store.commit('INIT', { 
        event: this.event,
        license: this.selectedLicense,
      });
      this.$router.push({name: 'checkoutCart'});*/
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `License`, disabled: true },
      ];
    },

    pricePerUser() {
      return this.prices[this.selectedLicense];
    },
    total() {
      return (this.quantity * this.pricePerUser) + (this.fullService ? 250 : 0);
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
  .pricing-details {
    .v-sheet { border-radius: 10px !important; text-align: center;; }
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .small { font-size: 75%; display: inline-block; text-align: left;}
    .large { font-size: 200%;}
    .large + .small { margin-left: 5px; }
    .popular { border: solid 5px #00B46E !important; }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
</style>

